const Effects = () => {
  return (
    <div className="effect-wrap">
      <div className="effect effect-1" />
      {/* <div className="effect effect-2">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div> */}
      {/* <div className="effect effect-3" /> */}
      <div className="effect effect-4" />
      {/* <div className="effect effect-5">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div> */}
    </div>
  );
};

export default Effects;
